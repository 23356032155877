import React from 'react'
import Dashboard from './Components/Dashboard/Dashboard'

function App() {
    return (
        <div>
            <Dashboard />
        </div>
    )
}

export default App
